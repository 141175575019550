<template>
  <v-row justify="space-around" class="width-100pc">
    <v-col cols="12" sm="12" md="10">
      <v-expansion-panels>
        <!-- Informations générales -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="text-h6">Informations générales</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de modifier le nom, le propriétaire, la marque du point de
              vente.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopGeneralForm.title"
                          label="Titre du point de vente"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="shopGeneralForm.dealerId"
                          item-text="text"
                          item-value="value"
                          :items="clientSelfCarItems"
                          label="Client Self-Car"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="shopGeneralForm.brand"
                          item-text="text"
                          item-value="value"
                          :items="marqueItems"
                          label="Marque"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="shopGeneralForm.description"
                          outlined
                          name="input-7-4"
                          label="Description"
                          value=""
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    small
                    class="mx-2"
                    fab
                    dark
                    color="teal"
                    @click="updateShop(shopGeneralForm)"
                  >
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Coordonnées -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Coordonnées</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de modifier les coordonnées postales du point de vente.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.socialReference"
                          label="Référence sociale"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.siren"
                          label="Siren"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.streetNumber"
                          label="N° de rue"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.streetName"
                          label="Nom de rue"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.zipcode"
                          label="Code postal"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.city"
                          label="Ville"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="shopContactForm.country"
                          :items="countryList"
                          label="Country"
                          item-value="code"
                          item-text="name"
                          placeholder="Select..."
                          required
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.phone"
                          type="phone"
                          label="Phone"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopContactForm.email"
                          type="email"
                          label="Email"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    small
                    class="mx-2"
                    fab
                    dark
                    color="teal"
                    @click="updateShop({ coordinates: { ...shopContactForm } })"
                  >
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Paramètres -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Paramètres</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de modifier la zone UTC, la devise, le code portail du
              parking, l'URL des conditions générales de vente.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="shopSettingForm.utcOffset"
                          item-text="text"
                          item-value="value"
                          :items="utcList"
                          label="Zone UTC"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="shopSettingForm.currency"
                          item-text="text"
                          item-value="value"
                          :items="currencyList"
                          label="Devise"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="shopSettingForm.portalCode"
                          label="Code du portail parking"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="shopSettingForm.cguUrl"
                          label="URL des conditions générales de vente"
                          class="mt-5"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Groupes -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Groupes</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet d'assigner le point de vente à un ou plusieurs groupes.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">
                          Aucun groupe disponible
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Localisation de la borne extérieure -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Localisation de la borne extérieure</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de gérer le contenu de la page Web dont le lien est envoyé
              par SMS.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">Coming soon</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Langues -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Langues</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de sélectionner les langues disponible sur les bornes.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">Coming soon</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Services dépôts et retraits de clés -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Services dépôts et retraits de clés</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de sélectionner les services disponibles sur les bornes.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">Coming soon</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Localisation de la borne extérieure -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Service SMS</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de sélectionner et configurer un fournisseur de service
              SMS.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">Coming soon</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Service e-Paiement -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Service e-Paiement</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de sélectionner et configurer un fournisseur de service
              ePaiement.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">Coming soon</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Bornes -->
        <v-expansion-panel class="mt-8">
          <v-expansion-panel-header>
            <div class="text-h6">Bornes</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="subtitle-2">
              Permet de gérer les bornes du point de vente.
            </div>
            <v-card elevation="0">
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div class="text-h6 text-center">Coming soon</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small class="mx-2" fab dark color="teal">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { SHOPS_EDIT } from "@/store/modules/shops/enums";

export default {
  name: "ShopEdit",
  data() {
    return {
      clientSelfCarItems: [
        {
          value: "600e4d3250e6c0004ba0d76b",
          text: "PSA",
        },
      ],
      marqueItems: [
        {
          value: "citroen",
          text: "Citroën",
        },
        {
          value: "peugeot",
          text: "Peugeot",
        },
        {
          value: "ds",
          text: "DS Automobiles",
        },
        {
          value: "opel",
          text: "Opel",
        },
        {
          value: "psa",
          text: "Multimarque",
        },
      ],
      countryList: [
        { name: "Afghanistan", code: "af" },
        { name: "land Islands", code: "ax" },
        { name: "Albania", code: "al" },
        { name: "Algeria", code: "dz" },
        { name: "American Samoa", code: "as" },
        { name: "AndorrA", code: "ad" },
        { name: "Angola", code: "ao" },
        { name: "Anguilla", code: "ai" },
        { name: "Antarctica", code: "aq" },
        { name: "Antigua and Barbuda", code: "ag" },
        { name: "Argentina", code: "ar" },
        { name: "Armenia", code: "am" },
        { name: "Aruba", code: "aw" },
        { name: "Australia", code: "au" },
        { name: "Austria", code: "at" },
        { name: "Azerbaijan", code: "az" },
        { name: "Bahamas", code: "bs" },
        { name: "Bahrain", code: "bh" },
        { name: "Bangladesh", code: "bd" },
        { name: "Barbados", code: "bb" },
        { name: "Belarus", code: "by" },
        { name: "Belgium", code: "be" },
        { name: "Belize", code: "bz" },
        { name: "Benin", code: "bj" },
        { name: "Bermuda", code: "bm" },
        { name: "Bhutan", code: "bt" },
        { name: "Bolivia", code: "bo" },
        { name: "Bosnia and Herzegovina", code: "ba" },
        { name: "Botswana", code: "bw" },
        { name: "Bouvet Island", code: "bv" },
        { name: "Brazil", code: "br" },
        { name: "British Indian Ocean Territory", code: "io" },
        { name: "Brunei Darussalam", code: "bn" },
        { name: "Bulgaria", code: "bg" },
        { name: "Burkina Faso", code: "bf" },
        { name: "Burundi", code: "bi" },
        { name: "Cambodia", code: "kh" },
        { name: "Cameroon", code: "cm" },
        { name: "Canada", code: "ca" },
        { name: "Cape Verde", code: "cv" },
        { name: "Cayman Islands", code: "ky" },
        { name: "Central African Republic", code: "cf" },
        { name: "Chad", code: "td" },
        { name: "Chile", code: "cl" },
        { name: "China", code: "cn" },
        { name: "Christmas Island", code: "cx" },
        { name: "Cocos (Keeling) Islands", code: "cc" },
        { name: "Colombia", code: "co" },
        { name: "Comoros", code: "km" },
        { name: "Congo", code: "cg" },
        { name: "Congo, The Democratic Republic of the", code: "cd" },
        { name: "Cook Islands", code: "ck" },
        { name: "Costa Rica", code: "cr" },
        { name: "Cote DIvoire", code: "ci" },
        { name: "Croatia", code: "hr" },
        { name: "Cuba", code: "cu" },
        { name: "Cyprus", code: "cy" },
        { name: "Czech Republic", code: "cz" },
        { name: "Denmark", code: "dk" },
        { name: "Djibouti", code: "dj" },
        { name: "Dominica", code: "dm" },
        { name: "Dominican Republic", code: "do" },
        { name: "Ecuador", code: "ec" },
        { name: "Egypt", code: "eg" },
        { name: "El Salvador", code: "sv" },
        { name: "Equatorial Guinea", code: "gq" },
        { name: "Eritrea", code: "er" },
        { name: "Estonia", code: "ee" },
        { name: "Ethiopia", code: "et" },
        { name: "Falkland Islands (Malvinas)", code: "fk" },
        { name: "Faroe Islands", code: "fo" },
        { name: "Fiji", code: "fj" },
        { name: "Finland", code: "fi" },
        { name: "France", code: "fr" },
        { name: "French Guiana", code: "gf" },
        { name: "French Polynesia", code: "pf" },
        { name: "French Southern Territories", code: "tf" },
        { name: "Gabon", code: "ga" },
        { name: "Gambia", code: "gm" },
        { name: "Georgia", code: "ge" },
        { name: "Germany", code: "de" },
        { name: "Ghana", code: "gh" },
        { name: "Gibraltar", code: "gi" },
        { name: "Greece", code: "gr" },
        { name: "Greenland", code: "gl" },
        { name: "Grenada", code: "gd" },
        { name: "Guadeloupe", code: "gp" },
        { name: "Guam", code: "gu" },
        { name: "Guatemala", code: "gt" },
        { name: "Guernsey", code: "gg" },
        { name: "Guinea", code: "gn" },
        { name: "Guinea-Bissau", code: "gw" },
        { name: "Guyana", code: "gy" },
        { name: "Haiti", code: "ht" },
        { name: "Heard Island and Mcdonald Islands", code: "hm" },
        { name: "Holy See (Vatican City State)", code: "va" },
        { name: "Honduras", code: "hn" },
        { name: "Hong Kong", code: "hk" },
        { name: "Hungary", code: "hu" },
        { name: "Iceland", code: "is" },
        { name: "India", code: "in" },
        { name: "Indonesia", code: "id" },
        { name: "Iran, Islamic Republic Of", code: "ir" },
        { name: "Iraq", code: "iq" },
        { name: "Ireland", code: "ie" },
        { name: "Isle of Man", code: "im" },
        { name: "Israel", code: "il" },
        { name: "Italy", code: "it" },
        { name: "Jamaica", code: "jm" },
        { name: "Japan", code: "jp" },
        { name: "Jersey", code: "je" },
        { name: "Jordan", code: "jo" },
        { name: "Kazakhstan", code: "kz" },
        { name: "Kenya", code: "ke" },
        { name: "Kiribati", code: "ki" },
        { name: "Korea, Democratic People'S Republic of", code: "kp" },
        { name: "Korea, Republic of", code: "kr" },
        { name: "Kuwait", code: "kw" },
        { name: "Kyrgyzstan", code: "kg" },
        { name: "Lao People'S Democratic Republic", code: "la" },
        { name: "Latvia", code: "lv" },
        { name: "Lebanon", code: "lb" },
        { name: "Lesotho", code: "ls" },
        { name: "Liberia", code: "lr" },
        { name: "Libyan Arab Jamahiriya", code: "ly" },
        { name: "Liechtenstein", code: "li" },
        { name: "Lithuania", code: "lt" },
        { name: "Luxembourg", code: "lu" },
        { name: "Macao", code: "mo" },
        { name: "Macedonia, The Former Yugoslav Republic of", code: "mk" },
        { name: "Madagascar", code: "mg" },
        { name: "Malawi", code: "mw" },
        { name: "Malaysia", code: "my" },
        { name: "Maldives", code: "mv" },
        { name: "Mali", code: "ml" },
        { name: "Malta", code: "mt" },
        { name: "Marshall Islands", code: "mh" },
        { name: "Martinique", code: "mq" },
        { name: "Mauritania", code: "mr" },
        { name: "Mauritius", code: "mu" },
        { name: "Mayotte", code: "yt" },
        { name: "Mexico", code: "mx" },
        { name: "Micronesia, Federated States of", code: "fm" },
        { name: "Moldova, Republic of", code: "md" },
        { name: "Monaco", code: "mc" },
        { name: "Mongolia", code: "mn" },
        { name: "Montenegro", code: "me" },
        { name: "Montserrat", code: "ms" },
        { name: "Morocco", code: "ma" },
        { name: "Mozambique", code: "mz" },
        { name: "Myanmar", code: "mm" },
        { name: "Namibia", code: "na" },
        { name: "Nauru", code: "nr" },
        { name: "Nepal", code: "np" },
        { name: "Netherlands", code: "nl" },
        { name: "Netherlands Antilles", code: "an" },
        { name: "New Caledonia", code: "nc" },
        { name: "New Zealand", code: "nz" },
        { name: "Nicaragua", code: "ni" },
        { name: "Niger", code: "ne" },
        { name: "Nigeria", code: "ng" },
        { name: "Niue", code: "nu" },
        { name: "Norfolk Island", code: "nf" },
        { name: "Northern Mariana Islands", code: "mp" },
        { name: "Norway", code: "no" },
        { name: "Oman", code: "om" },
        { name: "Pakistan", code: "pk" },
        { name: "Palau", code: "pw" },
        { name: "Palestinian Territory, Occupied", code: "ps" },
        { name: "Panama", code: "pa" },
        { name: "Papua New Guinea", code: "pg" },
        { name: "Paraguay", code: "py" },
        { name: "Peru", code: "pe" },
        { name: "Philippines", code: "ph" },
        { name: "Pitcairn", code: "pn" },
        { name: "Poland", code: "pl" },
        { name: "Portugal", code: "pt" },
        { name: "Puerto Rico", code: "pr" },
        { name: "Qatar", code: "qa" },
        { name: "Reunion", code: "re" },
        { name: "Romania", code: "ro" },
        { name: "Russian Federation", code: "ru" },
        { name: "RWANDA", code: "rw" },
        { name: "Saint Helena", code: "sh" },
        { name: "Saint Kitts and Nevis", code: "kn" },
        { name: "Saint Lucia", code: "lc" },
        { name: "Saint Pierre and Miquelon", code: "pm" },
        { name: "Saint Vincent and the Grenadines", code: "vc" },
        { name: "Samoa", code: "ws" },
        { name: "San Marino", code: "sm" },
        { name: "Sao Tome and Principe", code: "st" },
        { name: "Saudi Arabia", code: "sa" },
        { name: "Senegal", code: "sn" },
        { name: "Serbia", code: "rs" },
        { name: "Seychelles", code: "sc" },
        { name: "Sierra Leone", code: "sl" },
        { name: "Singapore", code: "sg" },
        { name: "Slovakia", code: "sk" },
        { name: "Slovenia", code: "si" },
        { name: "Solomon Islands", code: "sb" },
        { name: "Somalia", code: "so" },
        { name: "South Africa", code: "za" },
        { name: "South Georgia and the South Sandwich Islands", code: "gs" },
        { name: "Spain", code: "es" },
        { name: "Sri Lanka", code: "lk" },
        { name: "Sudan", code: "sd" },
        { name: "Suriname", code: "sr" },
        { name: "Svalbard and Jan Mayen", code: "sj" },
        { name: "Swaziland", code: "sz" },
        { name: "Sweden", code: "se" },
        { name: "Switzerland", code: "ch" },
        { name: "Syrian Arab Republic", code: "sy" },
        { name: "Taiwan, Province of China", code: "tw" },
        { name: "Tajikistan", code: "tj" },
        { name: "Tanzania, United Republic of", code: "tz" },
        { name: "Thailand", code: "th" },
        { name: "Timor-Leste", code: "tl" },
        { name: "Togo", code: "tg" },
        { name: "Tokelau", code: "tk" },
        { name: "Tonga", code: "to" },
        { name: "Trinidad and Tobago", code: "tt" },
        { name: "Tunisia", code: "tn" },
        { name: "Turkey", code: "tr" },
        { name: "Turkmenistan", code: "tm" },
        { name: "Turks and Caicos Islands", code: "tc" },
        { name: "Tuvalu", code: "tv" },
        { name: "Uganda", code: "ug" },
        { name: "Ukraine", code: "ua" },
        { name: "United Arab Emirates", code: "ae" },
        { name: "United Kingdom", code: "gb" },
        { name: "United States", code: "us" },
        { name: "United States Minor Outlying Islands", code: "um" },
        { name: "Uruguay", code: "uy" },
        { name: "Uzbekistan", code: "uz" },
        { name: "Vanuatu", code: "vu" },
        { name: "Venezuela", code: "ve" },
        { name: "Viet Nam", code: "vn" },
        { name: "Virgin Islands, British", code: "vg" },
        { name: "Virgin Islands, U.S.", code: "vi" },
        { name: "Wallis and Futuna", code: "wf" },
        { name: "Western Sahara", code: "eh" },
        { name: "Yemen", code: "ye" },
        { name: "Zambia", code: "zm" },
        { name: "Zimbabwe", code: "zw" },
      ],
      currencyList: [
        { value: "eur", text: "Euro" },
        { value: "usd", text: "Dollar" },
      ],
      utcList: [
        { value: "-660", text: "UTC-11" },
        { value: "-600", text: "UTC-10" },
        { value: "-540", text: "UTC-9" },
        { value: "-480", text: "UTC-8" },
        { value: "-420", text: "UTC-7" },
        { value: "-360", text: "UTC-6" },
        { value: "-300", text: "UTC-5" },
        { value: "-240", text: "UTC-4" },
        { value: "-180", text: "UTC-3" },
        { value: "-120", text: "UTC-2" },
        { value: "-60", text: "UTC-1" },
        { value: "0", text: "UTC" },
        { value: "60", text: "UTC+1" },
        { value: "120", text: "UTC+2" },
        { value: "180", text: "UTC+3" },
        { value: "240", text: "UTC+4" },
        { value: "300", text: "UTC+5" },
        { value: "360", text: "UTC+6" },
        { value: "420", text: "UTC+7" },
        { value: "480", text: "UTC+8" },
        { value: "540", text: "UTC+9" },
        { value: "600", text: "UTC+10" },
        { value: "660", text: "UTC+11" },
        { value: "720", text: "UTC+12" },
      ],
      shopGeneralForm: {
        title: "",
        dealerId: "",
        brand: "",
        description: "",
      },
      shopContactForm: {
        socialReference: "",
        siren: "",
        streetNumber: "",
        streetName: "",
        zipcode: "",
        city: "",
        country: "",
        phone: "",
        email: "",
      },
      shopSettingForm: {
        utcOffset: "",
        currency: "",
        portalCode: "",
        cguUrl: "",
      },
      shop: {},
    };
  },
  created() {
    this.shop = this.$store.getters.shopById[this.$route.params.id];

    this.initGeneralForm();
    this.initContactForm();
    this.initSettingForm();
  },
  methods: {
    initGeneralForm() {
      this.shopGeneralForm = {
        ...this.shopGeneralForm,
        title: this.shop.title,
        dealerId: this.shop.dealerId,
        brand: this.shop.brand,
        description: this.shop.description,
      };
    },
    initContactForm() {
      this.shopContactForm = {
        ...this.shopContactForm,
        socialReference: this.shop.coordinates.socialReference,
        siren: this.shop.coordinates.siren,
        streetNumber: this.shop.coordinates.streetNumber,
        streetName: this.shop.coordinates.streetName,
        zipcode: this.shop.coordinates.zipcode,
        city: this.shop.coordinates.city,
        country: this.shop.coordinates.country,
        phone: this.shop.coordinates.phone,
        email: this.shop.coordinates.email,
      };
    },
    initSettingForm() {
      this.shopGeneralForm = {
        ...this.shopGeneralForm,
        title: this.shop.title,
        dealerId: this.shop.dealerId,
        brand: this.shop.brand,
        description: this.shop.description,
      };
    },
    makeToast(variant, title, label) {
      this.$bvToast.toast(label, {
        title: title,
        variant: variant || "danger",
        solid: true,
      });
    },
    updateShop(data) {
      const formData = {
        ...this.$store.getters.shopById[this.$route.params.id],
        ...data,
      };
      this.$store
        .dispatch(SHOPS_EDIT, formData)
        .then((resp) => {
          if (resp.status === 200) {
            this.makeToast("Updated successfully");
          }
        })
        .catch(() => {
          this.makeToast("danger");
        });
    },
  },
};
</script>

<style scoped>
.subtitle-2 {
  color: grey;
}
</style>
